import React from "react";
import {Route, Switch} from "react-router-dom";

import {CssBaseline, makeStyles} from "@material-ui/core";

import NotFound from "../domain/NotFound/NotFound";
import Sidebar from "../components/Sidebar/Sidebar";
import Footer from "../components/Footer/Footer";

import routes from "../routes.js";

import {isLoggedIn} from "../components/Routes/Utils";
import PrivateRoute from "../components/Routes/PrivateRoute";
import PublicRoute from "../components/Routes/PublicRoute";

import './Admin.scss';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    appBar: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
    },
    drawerContainer: {
        overflow: 'auto',
    },
    toolbar: theme.mixins.toolbar,
    contentWrapper: {
        flexGrow: 1,
        padding: theme.spacing(3),
        display: "flex",
        "flex-direction": "column",
        "min-height": "calc(100vh - 20px)"
    },
    content: {
        flexGrow: 1
    },
}));

const switchRoutes = (
    <Switch>
        {routes.map((prop, key) => {
            const parameter = {
                path: prop.path,
                component: prop.component,
                key: key
            };
            if (prop.exact) {
                parameter["exact"] = true;
            }
            if (prop.private) {
                return <PrivateRoute {...parameter}/>;
            }
            if (prop.restricted) {
                return <PublicRoute restricted {...parameter}/>
            }
            return <PublicRoute {...parameter}/>;
        })}
        <Route component={NotFound}/>
    </Switch>
);

function Admin() {
    const classes = useStyles();

    return <div className={classes.root}>
        <CssBaseline/>
        {
            isLoggedIn() && <Sidebar routes={routes} classes={classes}/>
        }
        <div className={classes.contentWrapper}>
            <div className={classes.toolbar}/>
            <div className={classes.content}>{switchRoutes}</div>
            <Footer/>
        </div>
    </div>
}

export default Admin;
