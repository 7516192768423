import React from "react";

export default function Footer(props) {
    return <footer>
        <p>
            <span>
                &copy; {1900 + new Date().getYear()}{" "}
                <a
                    href="https://www.y-square.de"
                    target="_blank"
                    rel="noreferrer"
                >
                    Oxid CodingDays
                </a>
                , made with love for a better web
            </span>
        </p>
    </footer>
}