import React from "react";

import Grid from "@material-ui/core/Grid";
import {Card, CardContent, CardHeader, Container} from "@material-ui/core";

const Article = () => {

    return <Container>
        <Grid container className="grid" spacing={3}>
            <Grid item xs={12} sm={6} md={6}>
                <Card>
                    <CardHeader title="Test Chart"/>
                    <CardContent>
                        Articles
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    </Container>
}

export default Article;
