import React from "react";

import Grid from "@material-ui/core/Grid";
import {Container} from "@material-ui/core";
import RevenueChart from "../../components/Revenue/Revenue";

const Revenue = () => {
    return <Container>
            <RevenueChart/>
    </Container>
}

export default Revenue;
