import React from 'react';
import ReactDOM from 'react-dom';

import {createBrowserHistory} from "history";
import {Route, Switch, Redirect, BrowserRouter} from "react-router-dom";
import Admin from "./layout/Admin";

import {ApolloProvider} from '@apollo/client/react';
import {ApolloClient, createHttpLink, InMemoryCache} from "@apollo/client";
import {setContext} from "@apollo/client/link/context";
import {AUTH_TOKEN} from "./constants";

import "./index.scss"

const hist = createBrowserHistory();

const authLink = setContext((_, { headers }) => {
    const token = localStorage.getItem(AUTH_TOKEN);
    return {
        headers: {
            ...headers,
            authorization: token ? `Bearer ${token}` : ''
        }
    };
});

const httpLink = createHttpLink({
    uri: 'https://ce63.y-square.de/graphql'
});

const client = new ApolloClient({
    link: authLink.concat(httpLink),
    uri: 'https://ce63.y-square.de/graphql',
    cache: new InMemoryCache()
});



ReactDOM.render(
    <React.StrictMode>
        <BrowserRouter history={hist}>
            <ApolloProvider client={client}>
                <Switch>
                    <Route path="/admin" component={Admin}/>
                    <Redirect from="/" to="/admin/login"/>
                </Switch>
            </ApolloProvider>
        </BrowserRouter>,
    </React.StrictMode>,
    document.getElementById('root')
);
