import OrderPage from "./domain/Order/Order";
import RevenuePage from "./domain/Revenue/Revenue";
import ArticlePage from "./domain/Article/Article";

import {Dashboard, AccountCircle, ShoppingBasket, Label} from "@material-ui/icons";
import Login from "./domain/Login/Login";

export const ROUTES = {
    "LOGIN": "/admin/login",
    "DEFAULT": "/admin/orders"
}

const dashboardRoutes = [
    {
        path: "/admin/orders",
        name: "Orders",
        icon: Dashboard,
        component: OrderPage,
        sidebar: true,
        private: true
    },
    {
        path: "/admin/revenues",
        name: "Revenues",
        icon: ShoppingBasket,
        component: RevenuePage,
        sidebar: true,
        private: true
    },
    {
        path: "/admin/articles",
        name: "Articles",
        icon: Label,
        component: ArticlePage,
        sidebar: true
    },
    {
        path: "/admin/login",
        name: "Login",
        icon: AccountCircle,
        component: Login,
        exact: true,
        sidebar: false,
        restricted: true
    }
]

export default dashboardRoutes;
