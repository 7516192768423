import React, {useState} from "react";

import {Card, CardContent, CardHeader, Grid, Paper, TextField} from "@material-ui/core";
import {gql, useQuery} from "@apollo/client";
import {ArgumentAxis, Chart, LineSeries, ValueAxis} from "@devexpress/dx-react-chart-material-ui";
import {BarSeries} from "@devexpress/dx-react-chart";

const REVENUE_QUERY = gql`
    query revenues($start: String!, $end: String!){
        revenues(
            from: $start,
            to: $end
        ) {
            date
            paid
            unpaid
            total
        }
    }
`;

const Revenue = () => {
    const [formState, setFormState] = useState({
        start: '2021-04-22',
        end: '2021-04-26'
    });

    const {called, loading, data} = useQuery(REVENUE_QUERY, {
        variables: {
            start: formState.start,
            end: formState.end
        }
    });

    let content = "";
    if (called && loading) {
        content = <p>Loading ...</p>
    } else if (called) {
        content = '';
        console.log(data)
    }

    return <Grid container className="grid" spacing={3}>

        {data && <Grid item xs={12} sm={6} md={6}>
            <Card>
                <CardHeader title="Unpaid"/>
                <CardContent>
                    <Paper>
                        <Chart data={data.revenues}>
                            <ArgumentAxis/>
                            <ValueAxis/>
                            <BarSeries name="Total" valueField="unpaid" argumentField="date" color="green"/>
                        </Chart>
                    </Paper>
                </CardContent>
            </Card>
            <Card>
                <CardHeader title="Paid"/>
                <CardContent>
                    <Paper>
                        <Chart data={data.revenues}>
                            <ArgumentAxis/>
                            <ValueAxis/>
                            <BarSeries name="Total" valueField="paid" argumentField="date" color="green"/>
                        </Chart>
                    </Paper>
                </CardContent>
            </Card>
            <Card>
                <CardHeader title="Total"/>
                <CardContent>
                    <Paper>
                        <Chart data={data.revenues}>
                            <ArgumentAxis/>
                            <ValueAxis/>
                            <BarSeries name="Total" valueField="total" argumentField="date" color="green"/>
                        </Chart>
                    </Paper>
                </CardContent>
            </Card>
        </Grid>}
        <Grid item xs={12} sm={6} md={6}>
            <Card>
                <CardHeader title="Revenue"/>
                <CardContent>
                    <Grid container>
                        <Grid item>
                            <TextField
                                label="Start date"
                                type="date"
                                defaultValue={formState.start}
                                onChange={(e) =>
                                    setFormState({
                                        ...formState,
                                        start: e.target.value
                                    })}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                        <Grid item>
                            <TextField
                                label="End date"
                                type="date"
                                defaultValue={formState.end}
                                onChange={(e) =>
                                    setFormState({
                                        ...formState,
                                        end: e.target.value
                                    })}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                    </Grid>
                    {content}
                </CardContent>
            </Card>
        </Grid>
    </Grid>
}

export default Revenue;