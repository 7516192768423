import React from "react";

import Grid from "@material-ui/core/Grid";

import './Order.scss';
import {Box, Container, Typography} from "@material-ui/core";
import OrderCountByDate from "../../components/OrderCountByDate/OrderCountByDate";
import {USERNAME} from "../../constants";


const Order = () => {
    const username = localStorage.getItem(USERNAME);

    return <Container>
        <Box mb={3}>
            <Typography variant="h2">Hallo {username}!</Typography>
            <Typography variant="subtitle" gutterBottom>Dein Shop brummt.</Typography>
        </Box>
        <Grid container className="grid" spacing={3}>
            <Grid item xs={12} sm={6} md={6}>
                <OrderCountByDate/>
            </Grid>
        </Grid>
    </Container>
}

export default Order;
