import React from 'react';
import {Route, Redirect} from 'react-router-dom';
import {ROUTES} from '../../routes';
import {isLoggedIn} from './Utils';

function PublicRoute(props) {
    const {component: Component, restricted = false, ...rest} = props;

    const render = props => {
        if (isLoggedIn() && restricted) {
            return <Redirect to={ROUTES.DEFAULT}/>;
        }

        return <Component {...props} />;
    };

    return <Route {...rest} render={render}/>;
}

export default PublicRoute;