import React, {useState} from "react";

import {Card, CardHeader, CardContent, Grid, TextField, Select, MenuItem} from "@material-ui/core";
import {gql, useQuery} from "@apollo/client";
import {ArgumentAxis, Chart, BarSeries, ValueAxis} from '@devexpress/dx-react-chart-material-ui';

const ORDER_COUNT_BY_DATE = gql`
query reportCountByDateDiff($start: String!, $end: String!, $interval: String!){
    reportCountByDateDiff(
      dateBetween: {
        between:[
          $start,
          $end
        ]
      },
        interval: $interval
    ){
    orderIdentifier,
    orderInterval,
    orderCount
    }
}
`;

const OrderCountByDate = () => {
    const [formState, setFormState] = useState({
        start: '2021-04-10',
        end: '2021-04-22',
        interval: 'day'
    });

    const {loading, error, data} = useQuery(ORDER_COUNT_BY_DATE, {
        variables: {
            start: formState.start,
            end: formState.end,
            interval: formState.interval
        }
    });

    let content = "";
    if (loading) {
        content = <p>loading1 ...</p>
    } else if (error) {
        content = <p>Error! {error.message} {error.code}</p>
    } else if (data) {
        console.log(data.reportCountByDateDiff);
        content = <Chart
            data={data.reportCountByDateDiff}
        >
            <ArgumentAxis/>
            <ValueAxis/>
            <BarSeries
                name="Bestellungen"
                valueField="orderCount"
                argumentField="orderIdentifier"
            />
        </Chart>
    }

    return <Card>
        <CardHeader title="Order Count By Date Diff"/>
        <CardContent>
            <Grid container spacing={1}>
                <Grid item>
                    <TextField
                        label="Start date"
                        type="date"
                        defaultValue={formState.start}
                        onChange={(e) =>
                            setFormState({
                                ...formState,
                                start: e.target.value
                            })}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>
                <Grid item>
                    <TextField
                        label="End date"
                        type="date"
                        defaultValue={formState.end}
                        onChange={(e) =>
                            setFormState({
                                ...formState,
                                end: e.target.value
                            })}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>
                <Grid item>
                    <Select
                        label="Interval"
                        value={formState.interval}
                        onChange={(e) =>
                            setFormState({
                                ...formState,
                                interval: e.target.value
                            })}
                    >
                        <MenuItem value="day">Tag</MenuItem>
                        <MenuItem value="week">Woche</MenuItem>
                        <MenuItem value="month">Monat</MenuItem>
                        <MenuItem value="year">Jahr</MenuItem>
                        <MenuItem value="quarter">Quartal</MenuItem>
                    </Select>
                </Grid>
            </Grid>
            {content}
        </CardContent>
    </Card>
}

export default OrderCountByDate;