import React, {useState} from "react";
import Loading from '../../components/Helper/Loading';
import {Avatar, Box, Button, Card, CardContent, Container, CssBaseline, Grid, Typography} from "@material-ui/core";
import {AUTH_TOKEN, USERNAME} from '../../constants';

import {gql, useLazyQuery} from '@apollo/client';
import {Redirect} from "react-router-dom";
import {LockOpen} from "@material-ui/icons";
import CustomInput from "../../components/CustomInput/CustomInput";

const LOGIN_QUERY = gql`
    query LoginQuery(
        $username: String!
        $password: String!
    ) {
        token(username: $username, password: $password)
    }
`;

const Login = () => {
    const [formState, setFormState] = useState({
        login: true,
        username: '',
        password: '',
        name: ''
    });

    const [login, {called, loading, data}] = useLazyQuery(LOGIN_QUERY, {
        variables: {
            username: formState.username,
            password: formState.password
        }
    });

    if (called && loading) {
        return <Loading/>
    }

    if (data) {
        localStorage.setItem(AUTH_TOKEN, data.token);
        localStorage.setItem(USERNAME, formState.username)
    }

    if (localStorage.getItem(AUTH_TOKEN)) {
        return <Redirect to="/admin/orders"/>
    }

    return <Container component="main" maxWidth="xs">
        <CssBaseline/>
        <Card>
            <CardContent>
                <Box mb={3} mt={3}>
                    <Grid container spacing={3} alignItems="center" justify="center">
                        <Avatar>
                            <LockOpen fontSize="default"/>
                        </Avatar>
                    </Grid>
                </Box>
                <Box mb={3}>
                    <Grid container spacing={3} alignItems="center" justify="center">
                        <Typography component="h1" variant="h4">Dashboard Login</Typography>
                    </Grid>
                </Box>
                <form noValidate>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <CustomInput
                                variant="outlined"
                                required
                                fullWidth
                                id="email"
                                label="Email Address"
                                name="email"
                                autoComplete="email"
                                labelText="Email"
                                formControlProps={{
                                    fullWidth: true
                                }}
                                handleChange={(e) =>
                                    setFormState({
                                        ...formState,
                                        username: e.target.value
                                    })
                                }
                                type="text"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <CustomInput
                                variant="outlined"
                                required
                                fullWidth
                                name="password"
                                label="Password"
                                type="password"
                                id="password"
                                autoComplete="current-password"
                                labelText="Password"
                                formControlProps={{
                                    fullWidth: true
                                }}
                                handleChange={(e) =>
                                    setFormState({
                                        ...formState,
                                        password: e.target.value
                                    })
                                }
                            />
                        </Grid>
                        <Grid item xs={12}>
                        <Button
                            type="button"
                            fullWidth
                            variant="outlined"
                            color="primary"
                            onClick={() => login()}
                            disabled={!formState.username || !formState.password}>
                            Log in
                        </Button>
                        </Grid>
                    </Grid>
                </form>
            </CardContent>
        </Card>
    </Container>
}

export default Login;