import React from 'react';
import {Route, Redirect} from 'react-router-dom';
import {ROUTES} from '../../routes';
import {isLoggedIn} from './Utils';

function PrivateRoute(props) {
    const {component: Component, ...rest} = props;

    const render = props => {
        if (!isLoggedIn()) {
            return <Redirect to={ROUTES.LOGIN}/>;
        }

        return <Component {...props} />;
    };

    return <Route {...rest} render={render}/>;
}

export default PrivateRoute;