import React from "react";

import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";


export default function CustomInput(props) {
    const {
        formControlProps,
        labelText,
        id,
        labelProps,
        inputProps,
        handleChange,
        type
    } = props;

    return (
        <FormControl {...formControlProps}>
            {labelText !== undefined ? (
                <InputLabel
                    htmlFor={id}
                    {...labelProps}
                >
                    {labelText}
                </InputLabel>
            ) : null}
            <Input
                id={id}
                onChange={handleChange}
                {...inputProps}
                type={type}
            />
        </FormControl>
    );
}

